import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import { Background } from './trainings';

import Layout from '../components/layout';
import Card from '../components/Card/UserCard';
import UserProfile from '../components/UserProfile';
import moment from 'moment';
import qs from 'query-string';
import TextField from '@material-ui/core/TextField';

import t from 'translit';
import map from 'translit-russian';

const translit = t(map);

class Athletes extends Component {
  constructor(props) {
    super(props);
    const parsed = qs.parse(window.location.search);
    this.filterCriteria = parsed.user;
    this.props.firebase.base.listenToCollection('users', {
      context: this,
      state: 'users',
      then() {
        this.props.firebase.getUsers(this.filterCriteria).then(users => {
          if (this.mount) this.setState({ users });
        });
      }
    });
  }

  state = {
    users: [],
    lastMonthActivity: [],
    lastWeekActivity: [],
    search: ''
  };

  async componentDidMount() {
    this.mount = true;
    if (this.props.firebase) {
      this.props.firebase.getUsers(this.filterCriteria).then(users =>
        this.setState({ users }, async () => {
          const usersWithRating = await Promise.all(
            this.state.users.map(async user => {
              if (user.id) {
                const lastMonthRating = await this.props.firebase.getLastMonthRating(user.id);
                if (user.id === 't3ccee9LY5UEcBCCz2Aep7rSkDy1') {
                }
                const lastMonthRatingSummery = lastMonthRating.reduce((acc, cur) => {
                  return acc + (parseInt(cur.ratingInc) || 0);
                }, 0);
                return {
                  ...user,
                  lastMonthRatingSummery
                };
              }
              return user;
            })
          );
          this.setState({ users: usersWithRating });
        })
      );

      if (this.filterCriteria) {
        this.props.firebase.getLastMonthActivity(this.filterCriteria).then(lastMonthActivity => {
          const weekAgo = moment().subtract(1, 'week');
          const lastWeekActivity = lastMonthActivity.filter(train => {
            return moment(train.Date.toDate()).isAfter(weekAgo);
          });
          this.setState({ lastWeekActivity });
          this.setState({ lastMonthActivity });
        });

        this.props.firebase.base.listenToCollection('users', {
          context: this,
          state: 'users',
          then() {
            this.props.firebase.getUsers(this.filterCriteria).then(users => {
              if (this.mount) this.setState({ users });
            });
          }
        });
      }
    }
  }
  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    const currentUser = this.props.firebase?.auth?.currentUser?.uid;
    if (!this.state.users.length) {
      return null;
    }

    if (this.state.users.length === 1) {
      const [user] = this.state.users;

      return (
        <Background>
          <UserProfile
            {...user}
            currentUser={currentUser}
            setUserMoney={this.props.trainings.setUserMoney}
            addUserMoney={this.props.trainings.addUserMoney}
            getRatingLogs={this.props.trainings.getRatingLogs}
            addRemark={this.props.trainings.addRemark}
            lastMonthActivity={this.state.lastMonthActivity.length}
            lastWeekActivity={this.state.lastWeekActivity.length}
            lastMonthRatingSummery={this.state.lastMonthRatingSummery}
            key={user.id}
          />
        </Background>
      );
    }
    return (
      <Background>
        <div className="flex-bar">
          <div className="white-text">
            <TextField
              onChange={e => {
                this.setState({ search: e.target.value });
              }}
              value={this.state.search}
              placeholder={'Поиск'}
              style={{ color: 'white' }}
            />
          </div>
          <div className="white-text hidden-sm  " />
          <div className="white-text hidden-md" />
          <div className="white-text">В рейтинге: {this.state.users.length} спортсмена</div>
        </div>
        <div className={'flex-container'}>
          {this.state.users
            .sort((a, b) => {
              return b.lastMonthRatingSummery - a.lastMonthRatingSummery;
            })
            .filter(user => {
              const userName = user.username.toLowerCase();
              const query = this.state.search.toLowerCase();
              return (
                userName.includes(query) ||
                userName.includes(translit(query)) ||
                translit(query).includes(userName) ||
                translit(userName).includes(query)
              );
            })
            .map(user => (
              <Card
                {...user}
                currentUser={currentUser}
                setUserMoney={this.props.trainings.setUserMoney}
                addUserMoney={this.props.trainings.addUserMoney}
                addUserRating={this.props.trainings.addUserRating}
                key={user.id}
                {...this.props}
              />
            ))}
        </div>
      </Background>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Athletes));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
