import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { isAdmin } from '../utils';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import swal from 'sweetalert';
import styled from 'styled-components';
import moment from 'moment';
import { SwitchOnHover } from '../components/Card/UserCard';
import { withPrefix } from 'gatsby';

const styles = theme => ({
  card: {
    display: 'flex',
    margin: 10,
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    position: 'relative'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 99
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: '70vw',
    height: '90vh',
    backgroundPosition: '50% 30%'
  }
});

const Text = styled.div`
  font-size: 16px;

  .remark {
    font-weight: 800;
  }
  label {
    color: white;
    background-color: #c71309;
    padding: 2px 7px;
    border-radius: 3px;
  }
  @media (max-width: 700px) {
    .hideOnMobile {
      display: none;
    }
    font-size: 14px;
  }
`;
const Skew = styled.div`
  position: absolute;
  transform: skewX(3deg);
  z-index: 1;
  background-color: white;
  height: 100vh;
  top: 0;
  width: 110px;
  right: 25%;
  @media (max-width: 700px){
        transform: skewX(2deg);
        right: 16%;

    }
  }
`;

const StyledIconButton = styled(IconButton)`
  outline: none !important;
`;
const StyledList = styled.ul`
  font-size: 10px;
  color: gray;
`;

class MediaControlCard extends Component {
  state = {
    showLog: false,
    logs: []
  };

  showLog = () => {
    this.setState({ showLog: !this.state.showLog });
  };

  componentDidMount() {
    this.mount = true;
    if (this.props.getRatingLogs) {
      this.props.getRatingLogs(this.props.id).then(logs => {
        this.setState({ logs });
      });
    }
  }
  render() {
    const {
      classes,
      photoURL,
      username,
      money,
      rating = 0,
      currentUser,
      id,
      setUserMoney,
      addUserMoney,
      lastMonthActivity,
      lastWeekActivity,
      remark = '',
      addRemark,
      avatarNumber,
      hoverAvatarNumber
    } = this.props;
    const admin = isAdmin({ id: currentUser });
    const adminOrCurrentUser = admin || currentUser === id;
    const url = photoURL.includes('firebasestorage') ? photoURL : photoURL + '?width=1500';

    const prompt = e => {
      e.stopPropagation();
      swal({
        text: `Задати баланс для ${username}`,
        content: 'input',
        button: {
          text: 'ок'
        }
      }).then(money => {
        setUserMoney(id, money);
      });
    };

    const getStatus = (lastWeekActivity, lastMonthActivity) => {
      switch (true) {
        case lastWeekActivity > 3 || lastMonthActivity > 15:
          return 'Петя Саган';
        case lastWeekActivity > 2 || lastMonthActivity > 10:
          return 'Crazy spinner';
        case lastWeekActivity > 1 || lastMonthActivity > 7:
          return 'Упевнене зростання';
        case lastWeekActivity > 0 || lastMonthActivity > 5:
          return 'Так само, тільки швидше)';
        default:
          return 'Початківець';
      }
    };

    const promptAdd = e => {
      e.stopPropagation();

      swal({
        text: `Додати гроші для ${username}`,
        content: 'input',
        button: {
          text: 'ок'
        }
      }).then(money => {
        addUserMoney(id, money);
      });
    };

    const promptAddRemark = e => {
      e.stopPropagation();

      swal({
        text: `Побажання для ${username}`,
        content: 'input',
        button: {
          text: 'ок'
        }
      }).then(text => {
        addRemark(id, text);
      });
    };
    const status = getStatus(lastWeekActivity, lastMonthActivity);

    return (
      <Card className={classes.card}>
        {avatarNumber ? (
          <SwitchOnHover
            className={classes.cover}
            image={withPrefix(`sigma/${avatarNumber}.jpg`)}
            hoverAvatarNumber={withPrefix(`sigma/${hoverAvatarNumber}.jpg`)}
          />
        ) : (
          <CardMedia className={classes.cover} image={url} />
        )}
        <Skew />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {username}
            </Typography>
            <h4>
              Рейтинг: {rating}
              <StyledIconButton aria-label="edit" onClick={this.showLog}>
                {this.state.showLog ? (
                  <ExpandLess style={{ fontSize: 18 }} />
                ) : (
                  <ExpandMore style={{ fontSize: 18 }} />
                )}
              </StyledIconButton>
              {this.state.showLog && (
                <StyledList>
                  {this.state.logs.map(({ ratingInc, comment, date }) => (
                    <li>
                      отримав {ratingInc} у зв'язку з {comment}{' '}
                      {moment(date.toDate()).format('DD MMMM')}
                    </li>
                  ))}
                </StyledList>
              )}
            </h4>
            <Typography
              variant="subtitle2"
              className="content"
              color="textSecondary"
              style={{ fontSize: 16 }}
            >
              {adminOrCurrentUser && <span>Баланс: {money}</span>}
              <Text>
                <div>
                  <span className="hideOnMobile">Тренувань за</span> минулий тиждень:{' '}
                  <span style={{ fontWeight: 800 }}>{lastWeekActivity}</span>{' '}
                </div>
                <div>
                  <span className="hideOnMobile">Тренувань за</span> минулий місяць:{' '}
                  <span style={{ fontWeight: 800 }}>{lastMonthActivity}</span>
                </div>
                <div>
                  Статус: <label>{status}</label>
                </div>
                <div>
                  Коментарі тренера:
                  <div className="remark">
                    {remark}
                    {admin && (
                      <StyledIconButton aria-label="Add" onClick={promptAddRemark}>
                        <Add style={{ fontSize: 18 }} />
                      </StyledIconButton>
                    )}
                  </div>
                </div>
              </Text>
              <br />
              {admin && (
                <div>
                  <StyledIconButton aria-label="edit" onClick={prompt}>
                    <EditIcon style={{ fontSize: 18 }} />
                  </StyledIconButton>
                  <StyledIconButton aria-label="Add" onClick={promptAdd}>
                    <Add style={{ fontSize: 18 }} />
                  </StyledIconButton>
                </div>
              )}
            </Typography>
          </CardContent>
        </div>
      </Card>
    );
  }
}

MediaControlCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MediaControlCard);
